import Typed from 'typed.js'

const typedJsBanner = () => {
  if (document.getElementById('banner-typed-text')) {
    new Typed('#banner-typed-text', {
      typeSpeed: 20,
      backDelay: 1500,
      loop: true,
      showCursor: false,
      stringsElement: '#typed-strings'
    });
  };
}

const typedJsYouAre = () => {
  if (document.getElementById('you-are-typed-text')) {
    new Typed('#you-are-typed-text', {
      typeSpeed: 40,
      loop: true,
      showCursor: false,
      stringsElement: '#typed-strings-you-are'
    })
  }
}

export { typedJsBanner, typedJsYouAre }