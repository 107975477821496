// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";

// Internal imports, e.g:

import { typedJsBanner, typedJsYouAre } from "../plugins/typedjs";
import { gotop } from "../components/gotop"
import { modalVideoHome } from "../components/modal_video_home"
import { contactModalOpen } from "../components/contact_modal_open"
import { btnTrix } from "../components/btn_trix"

document.addEventListener('turbolinks:load', () => {
  typedJsBanner();
  typedJsYouAre();
  gotop();
  modalVideoHome();
  contactModalOpen();
  btnTrix();
});

require("trix")
require("@rails/actiontext")