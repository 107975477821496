const btnTrix = () => {
  const trixContent = document.getElementsByClassName('trix-content')
  if (trixContent) {
    const links = document.querySelectorAll('.blog-content-show .trix-content a')
    links.forEach(link => {
      if (link.innerHTML.startsWith('btn-primary:')) {
        link.innerHTML = link.innerHTML.replace('btn-primary:', '')
        link.classList.add('btn')
        link.classList.add('btn-primary')
        link.outerHTML = "<div class='text-center'>" + link.outerHTML + "</div>"
      } else if (link.innerHTML.startsWith('btn-secondary:')) {
        link.innerHTML = link.innerHTML.replace('btn-secondary:', '')
        link.classList.add('btn')
        link.classList.add('btn-secondary')
        link.outerHTML = "<div class='text-center'>" + link.outerHTML + "</div>"
      } else if (link.innerHTML.startsWith('btn-info:')) {
        link.innerHTML = link.innerHTML.replace('btn-info:', '')
        link.classList.add('btn')
        link.classList.add('btn-info')
        link.outerHTML = "<div class='text-center'>" + link.outerHTML + "</div>"
      } else if (link.innerHTML.startsWith('btn-danger:')) {
        link.innerHTML = link.innerHTML.replace('btn-danger:', '')
        link.classList.add('btn')
        link.classList.add('btn-danger')
        link.outerHTML = "<div class='text-center'>" + link.outerHTML + "</div>"
      }
    });
  }

}

export { btnTrix }