const gotop = () => {
  const gotopLink = document.getElementById("gotop")
  if (gotopLink){
    window.onscroll = function() {scrollFunction()};
  
    function scrollFunction() {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        gotopLink.classList.add("active")
  
      } else {
        gotopLink.classList.remove("active")
      }
    }
  }
}

export { gotop}