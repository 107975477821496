const modalVideoHome = () => {
  $(document).ready(function() {
    var $videoSrc = "https://www.youtube.com/embed/fiDVHR1HvoU";

    // when the modal is opened autoplay it  
    $('#videoModal').on('shown.bs.modal', function (e) {
      $("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0"); 
    })

    // stop playing the youtube video when I close the modal
    $('#videoModal').on('hide.bs.modal', function (e) {
      $("#video").attr('src',$videoSrc); 
    })
  });
}

export {modalVideoHome}